import React from 'react'

const About = () => {
  return (
    <div name='about' className='w-full h-screen bg-[#0a192f] text-gray-300'>
        <div className='flex flex-col justify-center items-center w-full h-full'>
            <div className='max-w-[1000px] w-full grid grid-cols-2 gap-8'>
                <div className='sm:text-right pb-8 pl-4'>
                    <p className='text-4xl font-bold inline border-b-4 border-pink-600'>
                        About
                    </p>
                </div>
                <div></div>
                </div>
                <div className='max-2-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4'>
                    <div className='sm:text-right text-4xl font-bold'>
                        <p>Hi, I'm Milos, nice to meet you. Please take a look around</p>
                    </div>
                    <div>
                        <p>
                        I'm a passionate Software Engineer with a unique background in Real Estate. 
                        My experience has taught me how to think on my feet, pay attention to the details, 
                        and communicate effectively—all skills that translate perfectly into the tech
                         world. I love creating intuitive and efficient solutions that make people's
                          lives easier. I'm always learning, staying up to date with the latest trends,
                           and excited to take on new challenges in this ever-evolving industry.
                        </p>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default About